import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { SettingsContext } from 'providers/SettingsProvider';
import _ from 'lodash';
let pagesScrollY={};

export default function ScrollToTop() {
  const { appState, setAppState, parallaxNode } = useContext(SettingsContext);
  const { pathname } = useLocation();
  useEffect(() => {
    let timer;
    const handleBack=()=>{
      if (pagesScrollY[window.location.pathname]) {
        timer = setTimeout(()=>{
          const scrollY=pagesScrollY[window.location.pathname]||0;
          window.scrollTo({
            top: scrollY,
            left: 0,
            behavior: 'auto'
          });
        },10);
      }
    }
    window.addEventListener('popstate',handleBack);
    return ()=>{
      window.removeEventListener('popstate',handleBack);
      clearTimeout(timer);
    }
  }, []);
  useEffect(() => {
    if (parallaxNode) {
      const header=parallaxNode.parentNode;
      const amount = header.clientHeight;
      window.scrollTo({
        top: pathname==='/' ? 0 : amount,
        left: 0,
        behavior: window.scrollY<amount || pathname==='/' ? 'smooth' : 'auto',
      });
    }
  }, [pathname,parallaxNode]);
  useEffect(()=>{
      const handleScroll=_.debounce(()=>{
        pagesScrollY={ ...pagesScrollY, [pathname]:window.scrollY };
        setAppState((state)=>{return{...state,pagesScrollY}});
      },100);
      document.addEventListener('scroll',handleScroll);
      return ()=>document.removeEventListener('scroll',handleScroll);
  },[pathname,setAppState]);
  useEffect(()=>{
    pagesScrollY={...appState.pagesScrollY};
  },[appState])
  return null;
}
