import React, { useState, createContext, useEffect, useCallback, useRef } from "react";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';

export const SettingsContext = createContext({});

const SettingsProvider = ({children})=>{
  const { data,refetch } = useQuery(Queries.interface);
  const widthRef = useRef(0);
  const parallaxRef = useRef(null);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const [ screenSize, setScreenSize ] = useState("xxl");
  const [ parallaxNode, setParallaxNode ] = useState(null);
  const [ appState,setAppState ] = useState({
    orientation:'landscape',
    interface:[],
    parallaxNode:null,
    startScroll:0,
    endScroll:0,
  });
  useEffect(()=>{
    if (data) {
      setAppState(state=>{return{...state,interface:data.interface}})
    }
  },[data,setAppState]);
  const t=useCallback((cle,type='valeur')=>{
    const res=appState.interface.find(o=>o.cle===cle);
    if (res) {
      return res[type] || cle;
    }
    return cle;
  },[appState.interface]);
  useEffect(()=>{
    const handleOrientation = (m)=>{
      if (m.matches) {
        console.log('portrait');
        setAppState(state=>{return{...state,orientation:'portrait'}});
      } else {
        console.log('landscape');
        setAppState(state=>{return{...state,orientation:'landscape'}});
      }
    }
    window.matchMedia('(orientation: portrait)').addListener(handleOrientation);
    handleOrientation(window.matchMedia('(orientation: portrait)'));
    return ()=>{
      window.matchMedia('(orientation: portrait)').removeListener(handleOrientation);
    }
  },[setAppState]);
  useEffect(()=>{
    const resize=()=>{
      const width=document.body.clientWidth;
      if (widthRef.current!==width || parallaxRef.current!==parallaxNode) {
        widthRef.current=width;
        parallaxRef.current=parallaxNode;
        let size="xs";
        if (width>576) size="sm";
        if (width>768) size="md";
        if (width>992) size="lg";
        if (width>1200) size="xl";
        if (width>1600) size="xxl";
        setScreenSize(size);
        ["xs","sm","md","lg","xl","xxl"].forEach((s) => {
          if (s!==size) document.body.classList.remove(s);
        });
        if (parallaxNode) {
          const endScroll=parallaxNode.clientHeight;
          setAppState(state=>{return{...state,endScroll}});
        }
        document.body.classList.add(size);
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
      const vhmax = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh-max', `${vhmax}px`);
    }
    const scroll=()=>{
      if (window.scrollY===0) {
        setTimeout(()=>{
          widthRef.current=0;
          resize();
        },100);
      }
    }
    resize();
    window.addEventListener('scroll',scroll);
    window.addEventListener('resize',resize);
    window.addEventListener('pageshow',resize);
    return ()=>{
      window.removeEventListener('scroll',scroll);
      window.removeEventListener('resize',resize);
      window.removeEventListener('pageshow',resize);
    }
  },[setScreenSize,setAppState,parallaxNode]);
  return (
        <SettingsContext.Provider value={{appState,screenSize,setAppState,t,parallaxNode, setParallaxNode}}>
            {children}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
