import React, { useEffect, useContext } from "react";
import "./Agenda.scss";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import Utils from 'utils/Utils';
import Container from '@mui/material/Container';
import { SettingsContext } from "providers/SettingsProvider";
import { DateTime } from "luxon";

function Agenda() {
  const { t } = useContext(SettingsContext);
  const { data,refetch } = useQuery(Queries.agenda);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const { agenda } = data ? data : {};
  useEffect(()=>{
    document.title = t('agenda');
  },[t]);
  return <div className="agenda page">
    <Container maxWidth="md">
      {Utils.parseWithLinks(t('agenda','html'))}
      {agenda && agenda.map(evt=><div key={evt.id} className="evt">
      <div className="date">
        {DateTime.fromISO(evt.date).setLocale('fr').toLocaleString(DateTime.DATE_HUGE)} {evt.horaire && ' à '+DateTime.fromISO(evt.date).setLocale('fr').toLocaleString(DateTime.TIME_SIMPLE)}
      </div>
      <div className="lieu">
        {evt.lieu}
      </div>
      {evt.texte && <div className="desc">
        {Utils.parseWithLinks(evt.texte)}
      </div>}
      </div>)}
    </Container>
  </div>
}

export default Agenda;
