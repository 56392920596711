import { gql } from '@apollo/client';
const queries={
  presentation: gql`
    query Presentation{
      presentation {
        titre
        texte
        lineup
      }
      site {
        titre
      }
    }
  `,
  agenda:gql`
    query Agenda{
      agenda(filter: { status : { _eq: "published"}, date: {_gt: "$NOW"}}, sort: ["date"]) {
        id
        texte
        date
        horaire
        lieu
      }
    }
  `,
  blog:gql`
    query Blog{
      blog(filter: { status : { _eq: "published"}}, sort: ["-date"]) {
        id
        titre
        texte
        date
        slugs (sort: "-date_created") {
          slug
        }
      }
    }
  `,
  billet:gql`
    query Billet($id: GraphQLStringOrFloat, $slug: String!){
      blog(filter: { _and:[
        {status : { _eq: "published"}},
        {_or:[
          {id: { _eq:$id}},
          {slugs: {slug: {_eq : $slug}}},
        ]}
      ]}) {
        id
        titre
        texte
        date
        slugs (sort: "-date_created") {
          slug
        }
        diaporama {
          directus_files_id {
            id
            title
            type
            credits
          }
        }
      }
    }
  `,
  sons:gql`
    query Sons{
      sons {
        titre
        texte
        fichiers {
          directus_files_id {
            id
            title
            description
            filesize
            type
          }
        }
      }
    }
  `,
  videos:gql`
    query Videos{
      videos {
        titre
        texte
        videos
      }
    }
  `,
  site:gql`
    query Site{
      site {
        titre
        footer
        visuel {
          id
          credits
        }
        youtube
        facebook
        espacePro
      }
    }
  `,
  menu:gql`
    query Menu{
      site {
        titre
      }
      presentation {
        titre
      }
      videos {
        titre
      }
      sons {
        titre
      }
    }
  `,
  interface:gql`
    query Interface{
      interface {
        cle
        valeur
        html
      }
    }
  `,
}
export default queries;
