import React, { useState, createContext, useEffect, useCallback, useRef } from "react";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import Conf from 'utils/Conf';

export const PlaylistContext = createContext({});

const PlaylistProvider = ({children})=>{
  const { data,refetch } = useQuery(Queries.sons);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const [ playListState,setPlayListState ] = useState([]);
  const playersNodes = useRef({});
  useEffect(()=>{
    if (data && data.sons && data.sons.fichiers) {
      setPlayListState(data.sons.fichiers.map(o=>o.directus_files_id).filter(o=>o.type==="audio/mpeg").map((o,i)=>{return{
        ...o,
        playing:false,
        position:0,
        currentTime:0,
        seek:null,
        current:i===0,
      }}));
    }
  },[data,setPlayListState])
  const setPlayerState=useCallback((id,playerState)=>{
    setPlayListState(state=>{
      const newState=state.map(o=>{return{...o,playing:playerState.playing ? false : o.playing, current:false}});
      const idx=newState.findIndex(o=>o.id===id);
      return [
        ...newState.slice(0,idx),
        {...(newState[idx] || {}),...playerState, current:true},
        ...newState.slice(idx+1),
      ]
    })
  },[setPlayListState]);
  const pauseAll=useCallback((id,playerState)=>{
    setPlayListState(state=>state.map(o=>{return{...o,playing:false}}))
  },[setPlayListState]);
  const getPlayerState=useCallback((id)=>{
    setPlayListState(state=>{
      const idx=state.findIndex(o=>o.id===id);
      return state[idx] || {};
    });
  },[setPlayListState]);
  const onRefChange=useCallback((id)=>(node)=>{
    if (playersNodes.current && node) {
      const handleChange=()=>{
        setPlayerState(id,{currentTime:node.currentTime, position:node.currentTime/node.duration});
      }
      node.addEventListener('timeupdate',handleChange);
      playersNodes.current[id]=node;
    }
  },[setPlayerState]);
  useEffect(()=>{
    for(const player of playListState) {
      const node=playersNodes.current[player.id];
      if (node) {
        if (node.paused && player.playing) node.play();
        if (!node.paused && !player.playing) node.pause();
        if (player.seek !== null ) {
          node.currentTime=node.duration*player.seek;
          setPlayerState(player.id,{seek:null});
        }
      }
    }
  },[playListState,setPlayerState]);
  const pausedAll=playListState.reduce((acc,o)=>acc&&!o.playing,true);
  return (
        <PlaylistContext.Provider value={{setPlayerState,playListState,setPlayListState,getPlayerState,pauseAll,pausedAll}}>
            {children}
            {playListState.map((player)=><audio ref={onRefChange(player.id)} key={player.id} controls={false} src={Conf.apiUrl+`/assets/${player.id}`}/>)}
        </PlaylistContext.Provider>
    );
}
export default PlaylistProvider;
