import React, { useContext } from "react";
import "./AudioPlayers.scss";
import Player from 'components/Sons/Player';
import { PlaylistContext } from "providers/PlaylistProvider";
import { useLocation } from "react-router-dom";

function AudioPlayers() {
  const { playListState } = useContext(PlaylistContext);
  const player=playListState.find(o=>o.current);
  const { pathname } = useLocation();
  return <div className="audioplayers">
    {!pathname.startsWith('/sons') && player && <Player player={player}/>}
  </div>
}

export default AudioPlayers;
