import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import SettingsProvider from "providers/SettingsProvider";
import PlaylistProvider from "providers/PlaylistProvider";
import Conf from 'utils/Conf';
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { persistCacheSync, LocalStorageWrapper } from 'apollo3-cache-persist';
import { ParallaxProvider } from 'react-scroll-parallax';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const httpLink = createHttpLink({
  uri: Conf.apiUrl+"/graphql",
});
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: Conf.authToken ? `Bearer ${Conf.authToken}` : "",
    }
  }
});
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        items: {
          merge: (existing, incoming, opts) =>
            opts.mergeObjects(existing, incoming),
        },
      },
    },
  },
});
if (window.localStorage) {
  persistCacheSync({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    key:'cdaeac-appolo',
  });
}
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <SettingsProvider>
        <PlaylistProvider>
          <ParallaxProvider>
            <App />
          </ParallaxProvider>
        </PlaylistProvider>
      </SettingsProvider>
    </ApolloProvider>
  </React.StrictMode>
);
