import React, { useContext, useState } from "react";
import "./Player.scss";
import { PlaylistContext } from "providers/PlaylistProvider";
import Slider from '@mui/material/Slider';
import Utils from 'utils/Utils';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

function Player({player}) {
  const { setPlayerState } = useContext(PlaylistContext);
  const [ pos, setPos ] = useState({});
  const togglePlay=()=>{
    setPlayerState(player.id,{playing:!player.playing});
  }
  const handleChange=(e,v)=>{
    setPos(state=>{return{...state,[player.id]:v}});
  }
  const handleChangeOk=()=>{
    setPlayerState(player.id,{seek:pos[player.id]/100});
    setTimeout(()=>setPos(state=>{return{...state,[player.id]:null}}),0);
  }
  return <div key={player.id} className="player">
    <IconButton aria-label="togglePlay" onClick={togglePlay}>
      {player.playing ? <PauseIcon /> : <PlayArrowIcon />}
    </IconButton>
    <div className="player-infos">
      {player.title}
      {player.description && <div className="desc">{Utils.parseWithLinks(player.description)}</div>}
      <Slider className={'player-progress'+(player.playing?' visible':'')} preload="auto" size="small" value={pos[player.id]!==undefined && pos[player.id]!==null ? pos[player.id] : player.position*100} onChange={handleChange} onChangeCommitted={handleChangeOk}/>
    </div>
  </div>;
}
export default Player;
