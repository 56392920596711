import React, { useCallback, useState } from 'react';
import './Image.scss';

function Image({src,onLoaded,className,width,height,alt,rel}) {
    const [loaded,setLoaded ] = useState(false);
    const showImage=useCallback(()=>{
        setLoaded(true);
        if (onLoaded) onLoaded();
    },[onLoaded,setLoaded]);
    return <img
        className={'image' + (className? ' '+className : '') + (loaded ? ' loaded':'')}
        onLoad={showImage}
        width={width}
        height={height}
        alt={alt}
        rel={rel}
        src={src}/>;
}
export default Image;
