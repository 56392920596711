import React, { useEffect } from "react";
import "./Presentation.scss";
import { useLocation } from "react-router-dom";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import Utils from 'utils/Utils';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Presentation() {
  const { data,refetch } = useQuery(Queries.presentation);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const { titre,texte,lineup } = data ? data.presentation : {};
  const { titre:titreSite } = data ? data.site : {};
  const { pathname } = useLocation();
  useEffect(()=>{
    if (titre) document.title = pathname==='/' ? titreSite : titre;
  },[titre,titreSite,pathname]);
  return <div className="presentation page">
    <Container maxWidth="md">
      {Utils.parseWithLinks(texte)}
      {lineup && lineup.map((o,i)=><Accordion key={i} className="lineup">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h1>{o.nom}</h1>
          <Typography>{o.instruments}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Utils.parseWithLinks(o.bio)}
        </AccordionDetails>
      </Accordion>)}
    </Container>
  </div>
}

export default Presentation;
