import React, { useEffect, useContext } from "react";
import "./Sons.scss";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import Utils from 'utils/Utils';
import Container from '@mui/material/Container';
import { PlaylistContext } from "providers/PlaylistProvider";
import Player from './Player';

function Sons() {
  const { playListState } = useContext(PlaylistContext);
  const { data,refetch } = useQuery(Queries.sons);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const { titre, texte } = data ? data.sons : {};
  useEffect(()=>{
    if (titre) document.title = titre;
  },[titre]);
  return <div className="sons page">
    {playListState.length>0 && <Container maxWidth="md">
      {Utils.parseWithLinks(texte)}
      {playListState.map(player=><Player key={player.id} player={player}/>)}
    </Container>}
  </div>
}

export default Sons;
