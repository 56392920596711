import React, { useEffect, useContext } from "react";
import "./Blog.scss";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import Utils from 'utils/Utils';
import Container from '@mui/material/Container';
import { SettingsContext } from "providers/SettingsProvider";
import { DateTime } from "luxon";
import {
  Link,
} from "react-router-dom";

function Blog() {
  const { t } = useContext(SettingsContext);
  const { data,refetch } = useQuery(Queries.blog);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const { blog } = data ? data : {};
  useEffect(()=>{
    document.title = t('blog');
  },[t]);
  return <div className="blog page">
    <Container maxWidth="md">
      {Utils.parseWithLinks(t('blog','html'))}
      {blog && blog.map(billet=><div key={billet.id} className="billet">
        <div className="date">
          {DateTime.fromISO(billet.date).setLocale('fr').toLocaleString(DateTime.DATE_HUGE)}
        </div>
        <div className="titre">
          <Link to={`/blog/${billet.slugs && billet.slugs.length>0 ? billet.slugs[0].slug : billet.id}`}>{billet.titre}</Link>
        </div>
      </div>)}
    </Container>
  </div>
}

export default Blog;
