import React, { useEffect, useContext } from "react";
import "./Videos.scss";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import Utils from 'utils/Utils';
import Container from '@mui/material/Container';
import { PlaylistContext } from "providers/PlaylistProvider";

function Videos() {
  const { pauseAll, pausedAll } = useContext(PlaylistContext);
  const { data,refetch } = useQuery(Queries.videos);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const { titre, texte, videos } = data ? data.videos : {};
  useEffect(()=>{
    if (titre) document.title = titre;
  },[titre]);
  return <div className="videos page">
    <Container maxWidth="md">
      {Utils.parseWithLinks(texte)}
      {videos && videos.map((video,i)=><div key={i}>
        <h1>{video.titre}</h1>
        {Utils.parseWithLinks(video.texte)}
        <div className={'embed'+(pausedAll? ' can-play' : '')} onPointerDown={pauseAll}>
          {Utils.parseWithLinks(video.code_embed)}
        </div>
      </div>)}
    </Container>
  </div>
}

export default Videos;
