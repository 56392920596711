import React, { useEffect,useRef,useState,useCallback } from "react";
import "./Billet.scss";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import Utils from 'utils/Utils';
import Container from '@mui/material/Container';
import { DateTime } from "luxon";
import Image from 'components/Image';
import Conf from 'utils/Conf';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import IconButton from '@mui/material/IconButton';
import {
  useParams,
  Navigate,
} from "react-router-dom";
// import Swiper core and required modules
import { Navigation, Pagination, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


function Billet() {
  const { id } = useParams();
  const diaporamaRef=useRef(null);
  const [ fullscreen,setFullscreen ]=useState(false);
  const { data,refetch } = useQuery(Queries.billet,{variables:{
    id:isNaN(id) ? 0 : id,
    slug:id,
  }});
  useEffect(()=>{
    refetch();
  },[refetch]);
  const { date,titre,texte,diaporama } = data && data.blog && data.blog.length>0 ?  data.blog[0] : {};
  const toggleFullscreen=useCallback(()=>{
    if (diaporamaRef.current) {
      if (!document.fullscreenElement) {
        diaporamaRef.current.requestFullscreen().then(()=>setFullscreen(true)).catch((err) => {
          console.log(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
          );
        });
      } else {
        document.exitFullscreen();
        setFullscreen(false);
      }
    }
  },[setFullscreen]);
  useEffect(()=>{
    return ()=>{
      if(document.fullscreen) document.exitFullscreen();
    };
  },[]);
  useEffect(()=>{
    if (titre) document.title = titre;
  },[titre]);
  const images=(diaporama && diaporama.map(o=>o.directus_files_id).filter(o=>o.type.startsWith('image/'))) || [];
  return data && data.blog.length===0 ?
    <Navigate to="/"/>
    :
    <div className="billet page">
    <Container maxWidth="md">
      <div className="date">
        {DateTime.fromISO(date).setLocale('fr').toLocaleString(DateTime.DATE_HUGE)}
      </div>
      <h1>{titre}</h1>
      {Utils.parseWithLinks(texte)}
      {images.length>0 && <div className={'diaporama'+(fullscreen ? ' fullscreen':'')} ref={diaporamaRef} onDoubleClick={toggleFullscreen}>
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, A11y]}
          navigation
          pagination={{ clickable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          {images.map(({id})=><SwiperSlide key={id}>
            <Image className="img-responsive" src={id ? Conf.apiUrl+`/assets/${id}?key=diaporama` : null} alt={''}/>
          </SwiperSlide>)}
        </Swiper>
        <div className='fullscreen-toggle'>
          <IconButton aria-label="togglePlay" onClick={toggleFullscreen}>
            {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </div>
      </div>}
    </Container>
  </div>;
}

export default Billet;
