import React, { useEffect,useContext } from "react";
import "./Footer.scss";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import Utils from 'utils/Utils';
import Container from '@mui/material/Container';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { SettingsContext } from "providers/SettingsProvider";

function Footer() {
  const { t } = useContext(SettingsContext);
  const { data,refetch } = useQuery(Queries.site);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const { footer, facebook, youtube, espacePro } = data ? data.site : {};
  return <div className="footer">
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
          <div>
            {footer && Utils.parseWithLinks(footer)}
          </div>
        </Grid>
        <Grid xs={12} sm={6} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <div>
            {youtube && <a href={youtube} target="_blank" rel="noreferrer">
              <IconButton>
                <YouTubeIcon/>
              </IconButton>
            </a>}
            {facebook && <a href={facebook} target="_blank" rel="noreferrer">
              <IconButton>
                <FacebookIcon/>
              </IconButton>
            </a>}
          </div>
          <div className="spacer"></div>
          <div>
            {espacePro && <a href={espacePro} target="_blank" rel="noreferrer"><Button>{t('espace-pro')}</Button></a>}
          </div>
        </Grid>
      </Grid>
    </Container>
  </div>;
}

export default Footer;
