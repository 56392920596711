import React, { useContext, useEffect } from "react";
import "./Menu.scss";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import { SettingsContext } from "providers/SettingsProvider";
import { PlaylistContext } from "providers/PlaylistProvider";
import {
  NavLink,
  useLocation,
} from "react-router-dom";

function Menu({customRef}) {
  const { t, parallaxNode } = useContext(SettingsContext);
  const { playListState } = useContext(PlaylistContext);
  const { pathname } = useLocation();
  const { data,refetch } = useQuery(Queries.menu);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const { titre:site } = data ? data.site : {};
  const { titre:presentation } = data ? data.presentation : {};
  const { titre:sons } = data ? data.sons : {};
  const { titre:videos } = data ? data.videos : {};
  const scrollContent=(path)=>{
    const header=parallaxNode.parentNode;
    if (path===pathname) window.scrollTo({
      top: header.clientHeight,
      left: 0,
      behavior: 'smooth',
    })
  };
  return <nav className="menu">
      <div ref={customRef} className='background-mask'></div>
      <div className='menu-inner'>
        <div className='site'><NavLink to="/">{site}</NavLink></div>
        <ul>
          <li onClick={()=>scrollContent('/presentation')}><NavLink to="/presentation">{presentation}</NavLink></li>
          {playListState.length>0 && <li onClick={()=>scrollContent('/sons')}><NavLink to="/sons">{sons}</NavLink></li>}
          <li onClick={()=>scrollContent('/videos')}><NavLink to="/videos">{videos}</NavLink></li>
          <li onClick={()=>scrollContent('/agenda')}><NavLink to="/agenda">{t('agenda')}</NavLink></li>
          <li onClick={()=>scrollContent('/blog')}><NavLink to="/blog">{t('blog')}</NavLink></li>
        </ul>
      </div>
  </nav>;
}

export default Menu;
