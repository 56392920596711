import React, { useEffect, useContext, useCallback } from "react";
import "./Entete.scss";
import { useQuery } from '@apollo/client';
import Image from 'components/Image';
import Queries from 'utils/Queries';
import Conf from 'utils/Conf';
import { useParallax } from 'react-scroll-parallax';
import { SettingsContext } from "providers/SettingsProvider";

function Entete() {
  const { setParallaxNode, appState } = useContext(SettingsContext);
  const { startScroll,endScroll } = appState;
  const { data,refetch } = useQuery(Queries.site);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const { ref } = useParallax({
    translateY: [0,50],
    startScroll,
    endScroll
  });
  const onRefChange=useCallback((node)=>{
    if (node) {
      ref.current=node;
      setParallaxNode(node);
    }
  },[setParallaxNode,ref]);
  const { visuel } = data ? data.site : {};
  const { id,credits } = visuel || {};
  return <div className="entete">
    <div className="entete-image" ref={onRefChange}>
      <Image className="img-responsive" src={id ? Conf.apiUrl+`/assets/${id}?key=une` : null} alt={''}/>
    </div>
    <div className="credits">© {credits}</div>
  </div>
}

export default Entete;
