import React, {useContext,useEffect} from "react";
import './App.scss';

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Entete from 'components/Entete';
import Presentation from 'components/Presentation';
import Agenda from 'components/Agenda';
import Evenement from 'components/Evenement';
import Blog from 'components/Blog';
import Billet from 'components/Billet';
import Videos from 'components/Videos';
import Sons from 'components/Sons';
import AudioPlayers from 'components/AudioPlayers';
import Menu from 'components/Menu';
import Footer from 'components/Footer';
import { SettingsContext } from "providers/SettingsProvider";
import { useParallax } from 'react-scroll-parallax';
import ScrollToTop from "utils/ScrollToTop";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PlaylistContext } from "providers/PlaylistProvider";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#4e467f',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#016a66',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});



function App() {
  const { appState } = useContext(SettingsContext);
  const { playListState } = useContext(PlaylistContext);
  const { startScroll,endScroll } = appState;
  const {ref}=useParallax({
    startScroll,
    endScroll,
    opacity:[0.5,1.2]
  });
  useEffect(()=>{
    const handleScroll=()=>{
      if (window.scrollY>=endScroll) ref.current.style.opacity=1;
    }
    handleScroll();
    window.addEventListener('scroll',handleScroll);
    window.addEventListener('pageshow',handleScroll);
    return ()=>{
      window.removeEventListener('scroll',handleScroll);
      window.removeEventListener('pageshow',handleScroll);
    }
  },[endScroll, ref]);
  return (
  <ThemeProvider theme={theme}>
  <BrowserRouter>
    <div className="app">
      <Entete/>
      <Menu customRef={ref}/>
      <div className="content">
        <Routes>
          <Route path="/" element={<Presentation />}/>
          <Route path="/presentation" element={<Presentation />}/>
          <Route path="/agenda" element={<Agenda />}/>
          <Route path="/agenda/:id" element={<Evenement />}/>
          <Route path="/blog" element={<Blog />}/>
          <Route path="/blog/:id" element={<Billet />}/>
          <Route path="/videos" element={<Videos />}/>
          {playListState.length>0 && <Route path="/sons" element={<Sons />}/>}
          <Route path="*" element={<Navigate to="/" />}/>
        </Routes>
        <AudioPlayers/>
      </div>
      <Footer/>
    </div>
    <ScrollToTop/>
  </BrowserRouter>
  </ThemeProvider>
  );
}

export default App;
